import { ApiOpenSitesInput } from 'xo/graphql/api/inputs/open-sites-input.generated';
import { Exact } from 'xo/graphql/api/types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
import {
  ApiOpenSiteFragment,
  OpenSiteFragmentDoc,
} from './open-site-fragment.generated';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApiGetOpenSiteByShortCodeQueryVariables = Exact<{
  input: ApiOpenSitesInput;
}>;

export type ApiGetOpenSiteByShortCodeQuery = {
  __typename?: 'Query';
  siteByShortCode?: { __typename?: 'OpenSite' } & ApiOpenSiteFragment;
};

export const GetOpenSiteByShortCodeDocument = gql`
  query GetOpenSiteByShortCode($input: OpenSitesInput!) {
    __typename
    siteByShortCode(input: $input) {
      ...OpenSite
    }
  }
  ${OpenSiteFragmentDoc}
`;

export function useGetOpenSiteByShortCodeQuery(
  options: Omit<
    Urql.UseQueryArgs<ApiGetOpenSiteByShortCodeQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ApiGetOpenSiteByShortCodeQuery,
    ApiGetOpenSiteByShortCodeQueryVariables
  >({ query: GetOpenSiteByShortCodeDocument, ...options });
}

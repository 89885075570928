import { useLocalStorage } from '@mantine/hooks';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { IconButton } from 'xo/components/icon-button';
import { ANDROID_STORE_URL, IOS_STORE_URL } from 'xo/constants';
import { useOptionalCurrentUser } from 'xo/login/current-user-hooks';
import { SvgCross } from 'xo/svg/svg-cross';
import { AnalyticsEvent } from 'xo/utils/analytics-utils';
import { isRunningInWebView } from 'xo/webview/webview-events';
import { captureEvent } from '../../hooks/analytics';
import { LocalStorage } from '../shared/local-storage';

const DAYS_BETWEEN_DISMISSALS = 30;
const BANNER_HEIGHT = '64px';
const localStorageKey = LocalStorage.key('mobile-app-banner-dismissed-at', '1');

export const MobileAppBanner = () => {
  const user = useOptionalCurrentUser();

  const [lastDismissedAt, setLastDismissedAt] = useLocalStorage({
    key: localStorageKey,
  });

  const onDismiss = () => {
    setLastDismissedAt(dayjs().toISOString());
    captureEvent(AnalyticsEvent.MobileBannerDismissed);
  };

  // only show the banner if the user hasn't dismissed it in the last 30 days
  const isOutsideDismissalCooldown = useMemo(
    () =>
      !lastDismissedAt ||
      dayjs().isAfter(
        dayjs(lastDismissedAt).add(DAYS_BETWEEN_DISMISSALS, 'days'),
      ),
    [lastDismissedAt],
  );

  // only logged-in user who haven't enabled push notifications
  const isNotifiableUser = user && !user.preferences.methods.devicePush;

  // only on mobile devices accessing the website outside the webview (ie. not already in our mobile app)
  const isSupportedDevice = !isRunningInWebView() && (isAndroid || isIOS);

  const visible =
    isOutsideDismissalCooldown && isSupportedDevice && isNotifiableUser;

  const onGoToStore = () => {
    const url = isAndroid
      ? ANDROID_STORE_URL
      : isIOS
        ? IOS_STORE_URL
        : undefined;

    if (url) {
      window.open(url);
    }

    captureEvent(AnalyticsEvent.MobileBannerStoreClicked);
  };

  if (!isSupportedDevice) {
    return null;
  }

  return (
    <>
      <button
        className={classNames(
          'fixed left-0 top-0 z-50 h-screen w-screen cursor-auto bg-black',
          { 'pointer-events-none opacity-0': !visible, 'opacity-60': visible },
        )}
        role={visible ? 'button' : 'none'}
        aria-label={visible ? 'Dismiss mobile app banner' : undefined}
        onClick={onDismiss}
      />

      <div
        className="fixed bottom-0 left-0 z-50 flex w-screen transform items-center justify-between rounded-t-lg bg-grey-200 p-4 transition-transform duration-200 ease-in-out"
        style={{
          height: BANNER_HEIGHT,
          transform: `translateY(${visible ? 0 : BANNER_HEIGHT})`,
        }}
      >
        <div className="flex items-center gap-2">
          <IconButton
            variant="grey-dark"
            icon={<SvgCross />}
            onPress={onDismiss}
          />

          <div>
            <div className="h-12 w-12 rounded-lg bg-white p-1">
              <img src="/logo192.png" alt="Exoflare logo" />
            </div>
          </div>
          <div className="text-left">
            <div className="text-base font-bold leading-[1.3]">ExoFlare</div>
            <div className="text-sm font-medium leading-[1.3] text-grey-600">
              Open in the ExoFlare app
            </div>
          </div>
        </div>

        <button
          className="rounded-full bg-blue-400 px-6 py-2 text-lg font-medium text-white"
          onClick={onGoToStore}
        >
          OPEN
        </button>
      </div>
    </>
  );
};

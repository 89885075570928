import { ApiModule } from 'xo/graphql/api/enums/module.generated';
import { ApiSiteKind } from 'xo/graphql/api/enums/site-kind.generated';

import gql from 'graphql-tag';
import {
  ApiOpenOrganisationFragment,
  OpenOrganisationFragmentDoc,
} from './open-organisation-fragment.generated';
export type ApiOpenSiteFragment = {
  __typename?: 'OpenSite';
  id: string;
  shortCode: string;
  name: string;
  kind: ApiSiteKind;
  modules: Array<ApiModule>;
  organisation: {
    __typename?: 'OpenOrganisation';
  } & ApiOpenOrganisationFragment;
};

export const OpenSiteFragmentDoc = gql`
  fragment OpenSite on OpenSite {
    __typename
    id
    shortCode
    name
    kind
    organisation {
      ...OpenOrganisation
    }
    modules
  }
  ${OpenOrganisationFragmentDoc}
`;

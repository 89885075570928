import { FormInstance, Tooltip } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { width } from 'xo/styles/tailwind-theme';
import { FormItem } from '../forms/form';
import { InputNumber } from '../forms/input-number';
import { joinFieldNames, pluralize } from '../utils';
import {
  SettingsQuarantineNights,
  SettingsQuarantineNightsProps,
  quarantineNightsWrapperClassName,
} from './settings-quarantine-nights';
import {
  ApiSiteSummary,
  MovementRuleType,
  SettingsQuarantineKindToKindSummaryModel,
  getRuleId,
  getRuleTypeClassName,
} from './settings-quarantine-utils';

export interface SettingsQuarantineCellProps {
  source: ApiSiteSummary;
  target: ApiSiteSummary;
  editing: boolean;
  form: FormInstance;
  simpleTooltips?: boolean;
  summary: SettingsQuarantineKindToKindSummaryModel;
  onChange?: () => void;
  onOverrideNightsClick?: () => void;
}

export const SettingsQuarantineCell: React.FC<SettingsQuarantineCellProps> = ({
  source,
  target,
  editing,
  form,
  simpleTooltips,
  summary,
  onChange,
  onOverrideNightsClick,
}) => {
  const ruleId = getRuleId({ sourceId: source.id, targetId: target.id });
  const ruleIdName: NamePath = useMemo(() => ['ruleMap', ruleId], [ruleId]);
  const { nights, ruleType } = form.getFieldValue(ruleIdName) ?? {};

  const nightsText = pluralize(nights, 'nt');
  const isSameSite = source.id === target.id;

  const onChangeCb = useCallback(() => {
    form.setFields([
      {
        name: joinFieldNames([ruleIdName, 'ruleType']),
        value: 'movementRule' as MovementRuleType,
      },
    ]);
    onChange && onChange();
  }, [form, ruleIdName, onChange]);

  const type: SettingsQuarantineNightsProps['type'] =
    nights !== summary.kindToKindMap[source.kind][target.kind].nights
      ? 'error'
      : 'info';
  let component = (
    <SettingsQuarantineNights
      nights={nights}
      type={type}
      onClick={type === 'error' ? onOverrideNightsClick : undefined}
    />
  );
  let tooltip = `${nights} ${pluralize(nights, 'night')} required`;
  if (isSameSite) {
    component = <div className="text-center text-grey-600">Nil</div>;
    tooltip = 'No requirements';
  } else if (editing) {
    component = (
      <div
        className={classNames(
          quarantineNightsWrapperClassName,
          'rounded-full p-5 px-3',
          editing ? getRuleTypeClassName(ruleType) : undefined,
        )}
      >
        <FormItem name={joinFieldNames([ruleIdName, 'nights'])} noStyle>
          <InputNumber
            min={0}
            max={99}
            size="small"
            style={{ width: width[14] }}
            onChange={onChangeCb}
          />
        </FormItem>
        <span className="ml-2 hidden sm:block">{nightsText}</span>
      </div>
    );
  }

  return (
    <div
      title={!editing && simpleTooltips ? tooltip : undefined}
      className={classNames(
        quarantineNightsWrapperClassName,
        'items-center justify-center p-4 transition-colors duration-200 ease-in',
      )}
    >
      {tooltip && !editing && !simpleTooltips ? (
        <Tooltip trigger={['click', 'hover']} overlay={tooltip}>
          {React.cloneElement(component, {
            className: classNames('cursor-pointer', component.props.className),
          })}
        </Tooltip>
      ) : (
        component
      )}
    </div>
  );
};

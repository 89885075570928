import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgDelete = (props: SvgIconProps) => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4V4Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

import { useState } from 'react';
import { Box } from 'xo/core';
import { useDisclose } from 'xo/hooks/component-hooks';
import { useTransportSites } from 'xo/login/current-user-hooks';
import { spacing } from 'xo/styles/restyle/spacing';
import { Modal } from '../components/modal';
import { StatusMessage } from '../components/status-message';
import { ArrivalDashboardSelectFilePanel } from './arrival-dashboard-select-file-panel';
import { ArrivalDashboardUploadFile } from './arrival-dashboard-upload-file';

export type FileSelection = { file: File; error?: string };

interface ArrivalDashboardUploadModalProps {
  children: (props: { onOpen: () => void }) => React.ReactNode;
}

export const ArrivalDashboardUploadModal = ({
  children,
}: ArrivalDashboardUploadModalProps) => {
  const transportSites = useTransportSites();
  // we're assuming it's an org with only 1 abattoir site for now - ie. Swickers
  const abattoirSiteId = transportSites[0]?.id;

  const { isOpen, onOpen, onClose } = useDisclose();
  const [selection, onSelectFile] = useState<FileSelection>();

  const onRemove = () => {
    onSelectFile(undefined);
  };
  const onCloseCb = () => {
    onRemove();
    onClose();
  };

  return (
    <>
      {children({ onOpen })}
      <Modal
        open={isOpen}
        onCancel={onCloseCb}
        closable
        title="UPLOAD SCHEDULE"
        hideCancel
        hideOk
      >
        <Box minHeight={spacing['64']}>
          {selection && abattoirSiteId ? (
            <ArrivalDashboardUploadFile
              selection={selection}
              siteId={abattoirSiteId}
              onRemove={onRemove}
              onClose={onCloseCb}
            />
          ) : abattoirSiteId ? (
            <ArrivalDashboardSelectFilePanel onSelectFile={onSelectFile} />
          ) : (
            <StatusMessage type="info">
              There must be at least 1 site in your organisation to upload a
              schedule.
            </StatusMessage>
          )}
        </Box>
      </Modal>
    </>
  );
};

// generated by client/xo/graphql/codegen/graphql-codegen.yaml

import gql from 'graphql-tag';
import {
  ApiScheduledMovementStockEditabilityFragment,
  ScheduledMovementStockEditabilityFragmentDoc,
} from './scheduled-movement-stock-editability-fragment.generated';
export type ApiScheduledMovementEditabilityFragment = {
  __typename?: 'ScheduledMovementEditability';
  arrivalVariance: boolean;
  arrivalWeather: boolean;
  scheduledMovementIngestion: boolean;
  schedulingOrganisation: boolean;
  status: boolean;
  actualUnloadingStartDatetime: boolean;
  actualUnloadingStartWeather: boolean;
  destinationSite: boolean;
  stockAdd: boolean;
  stockRemove: boolean;
  actualArrivalDatetime: boolean;
  actualRamp: boolean;
  arrivedUnscheduled: boolean;
  scheduledArrivalDatetime: boolean;
  scheduledRamp: boolean;
  scheduledUnloadByDatetime: boolean;
  vehicle: boolean;
  comment: boolean;
  transporterOther: boolean;
  stockEdit: {
    __typename?: 'ScheduledMovementStockEditability';
  } & ApiScheduledMovementStockEditabilityFragment;
};

export const ScheduledMovementEditabilityFragmentDoc = gql`
  fragment ScheduledMovementEditability on ScheduledMovementEditability {
    __typename
    arrivalVariance
    arrivalWeather
    scheduledMovementIngestion
    schedulingOrganisation
    status
    actualUnloadingStartDatetime
    actualUnloadingStartWeather
    destinationSite
    stockAdd
    stockRemove
    stockEdit {
      ...ScheduledMovementStockEditability
    }
    actualArrivalDatetime
    actualRamp
    arrivedUnscheduled
    scheduledArrivalDatetime
    scheduledRamp
    scheduledUnloadByDatetime
    transporterOther: transporter
    vehicle
    comment
  }
  ${ScheduledMovementStockEditabilityFragmentDoc}
`;

import { Path } from 'react-native-svg';
import { SvgIcon, SvgIconProps } from './svg-icon';

export const SvgPlus = (props: SvgIconProps) => {
  const { fill } = props;

  return (
    <SvgIcon {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13V13Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

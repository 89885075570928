import { ApiModule } from 'xo/graphql/api/enums/module.generated';

import gql from 'graphql-tag';
export type ApiOpenOrganisationFragment = {
  __typename?: 'OpenOrganisation';
  id: string;
  name: string;
  modules: Array<ApiModule>;
};

export const OpenOrganisationFragmentDoc = gql`
  fragment OpenOrganisation on OpenOrganisation {
    __typename
    id
    name
    modules
  }
`;

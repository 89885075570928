import { sortBy } from 'lodash';
import { phoneInfoForPhoneRegion } from 'xo/constants';
import { ApiSupportedPhoneRegion } from 'xo/graphql/api/enums/supported-phone-region.generated';
import { ApiNotificationMethodsFragment } from 'xo/graphql/api/notification-methods-fragment.generated';
import { getCurrentTimezone } from '../date-utils';
import { createOptions } from './option-utils';
import { timeZoneCountryCodeMap } from './timezone-utils';
import { unreachable } from './validation-utils';

export const { options: countryCodeOptions, getLabel: getCountryCode } =
  createOptions(
    sortBy(
      Object.entries(phoneInfoForPhoneRegion).map(
        ([abbr, { countryCode }]) => ({
          key: abbr as ApiSupportedPhoneRegion,
          label: countryCode,
          value: abbr as ApiSupportedPhoneRegion,
        }),
      ),
      'label',
    ),
  );

/** Estimate the phone region of the user, based on their timezone (or the one explicitly specified) */
export const defaultPhoneRegion = ({
  timezone,
  defaultAbbr = ApiSupportedPhoneRegion.Au,
}: {
  timezone?: string;
  defaultAbbr?: ApiSupportedPhoneRegion;
} = {}): ApiSupportedPhoneRegion => {
  // default to the current timezone, as best we can guess
  const defaultTimezone = getCurrentTimezone();
  const countryCode = timeZoneCountryCodeMap[timezone ?? defaultTimezone];

  const isSupported =
    countryCode &&
    (Object.values(ApiSupportedPhoneRegion) as string[]).includes(countryCode);

  return isSupported ? (countryCode as ApiSupportedPhoneRegion) : defaultAbbr;
};

/** Check whether `value` only contains characters that can appear in a phone number.
 *
 * This does no real validation of whether `value` is a phone number (or a partial phone number).
 */
export const containsOnlyPhoneChars = (value: string) =>
  /^[0-9 +()-]*$/.test(value);

type PhoneNotificationMethods = keyof Pick<
  ApiNotificationMethodsFragment,
  'sms' | 'whatsapp'
>;

export type PhoneRegionNotificationConfig = Partial<
  Record<PhoneNotificationMethods, boolean>
>;

// FIXME Logic is duplicated by phone validation API, but we need to use this when we only know the region
export const getPhoneRegionNotificationConfig = (
  region: ApiSupportedPhoneRegion,
  isXoAdmin: boolean,
): PhoneRegionNotificationConfig => {
  switch (region) {
    case ApiSupportedPhoneRegion.Fj:
    case ApiSupportedPhoneRegion.Au:
      return {
        sms: true,
        // only XO admins can access WhatsApp settings in Australia and Fiji
        ...(isXoAdmin
          ? {
              whatsapp: true,
            }
          : {}),
      };
    case ApiSupportedPhoneRegion.Us:
    case ApiSupportedPhoneRegion.Nz:
    case ApiSupportedPhoneRegion.Ph:
      return {
        whatsapp: true,
      };
    default:
      return unreachable(region);
  }
};

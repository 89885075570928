import { ColumnProps } from 'antd/es/table';
import { useMemo } from 'react';
import { Box } from 'xo/core';
import { Select } from 'xo/forms/select';
import { SvgEye } from 'xo/svg/svg-eye';
import { capitalize } from '../utils';

interface TableColumnSelectProps<T> {
  columns: ColumnProps<T>[];
  selectedColumn: keyof T;
  onChange: (column: keyof T) => void;
}

export const TableColumnSelect = <T extends object>({
  columns,
  selectedColumn,
  onChange,
}: TableColumnSelectProps<T>) => {
  // only include columns that are not the first one
  const options = useMemo(
    () =>
      columns.slice(1).map(c => ({
        key: String(c.dataIndex),
        label: capitalize((c.title as string).toLowerCase()),
        value: String(c.dataIndex),
      })),
    [columns],
  );

  return (
    <Box flex={1}>
      <Select
        clearable={false}
        icon={<SvgEye />}
        value={String(selectedColumn)}
        options={options}
        onChange={v => onChange(v as keyof T)}
      />
    </Box>
  );
};

import { useEffect } from 'react';

export interface PageTitleProps {
  title?: string;
}

export const PageTitle = ({ title }: PageTitleProps) => {
  useEffect(() => {
    document.title = `ExoFlare ${title ? ` | ${title}` : ''}`;
  }, [title]);

  return null;
};

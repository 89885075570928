import classNames from 'classnames';
import { isEqual } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useWindowSize } from 'react-hanger';
import { Box } from 'xo/core';
import { useScreenSize, useScrollFlags } from '../../hooks/shared-hooks';
import { Table, TableColumnProps } from '../components/table';
import { FormUpdater } from '../forms/form';
import { AddExternalSiteButton } from './settings-quarantine-add-external-site-button';
import { SettingsQuarantineCell } from './settings-quarantine-cell';
import {
  QuarantineExternalSiteHeader,
  QuarantineSiteHeader,
} from './settings-quarantine-site-header';
import './settings-quarantine-table.overrides.css';
import {
  ApiSiteSummary,
  SettingsQuarantineForm,
  SettingsQuarantineKindToKindSummaryModel,
  getRuleId,
  isExternalSite,
} from './settings-quarantine-utils';

export interface SettingsQuarantineTableProps {
  sourceSites: ApiSiteSummary[];
  targetSites: ApiSiteSummary[];
  innerRef: React.RefObject<HTMLDivElement>;
  orgId: string;
  onCalcTableHeight?: () => void;
  editing: boolean;
  onCellChange?: () => void;
  onAddExternalSite: (site: ApiSiteSummary) => Promise<void>;
  onRemoveExternalSite: (siteId: string) => void;
  summary: SettingsQuarantineKindToKindSummaryModel;
  canEditQuarantine: boolean;
}

const SM_COL_LIMIT = 1;
const MD_COL_LIMIT = 3;

export const SettingsQuarantineTable: React.FC<
  SettingsQuarantineTableProps
> = ({
  sourceSites,
  targetSites,
  innerRef,
  editing,
  orgId,
  onCellChange,
  onAddExternalSite,
  onRemoveExternalSite,
  summary,
  canEditQuarantine,
}) => {
  const simpleTooltips = sourceSites.length * targetSites.length > 50;

  const existingSiteShortCodes = useMemo(
    () => sourceSites.map(s => s.shortCode),
    [sourceSites],
  );

  const renderCell = useCallback(
    (source: ApiSiteSummary, target: ApiSiteSummary) => {
      const ruleId = getRuleId({ targetId: target.id, sourceId: source.id });

      return (
        <FormUpdater<SettingsQuarantineForm>
          shouldUpdate={(p, n) =>
            !isEqual(p.ruleMap[ruleId], n.ruleMap[ruleId])
          }
        >
          {form => (
            <SettingsQuarantineCell
              form={form}
              source={source}
              target={target}
              editing={editing}
              simpleTooltips={simpleTooltips}
              summary={summary}
              onChange={onCellChange}
            />
          )}
        </FormUpdater>
      );
    },
    [editing, simpleTooltips, onCellChange, summary],
  );

  const columns: TableColumnProps<ApiSiteSummary>[] = useMemo(
    () => [
      {
        columnKey: 'source',
        dataIndex: 'name',
        title: (
          <div className="relative">
            <div className="absolute left-0 top-0  ml-4">From</div>
            <div className="absolute bottom-0 right-0 mr-4">To</div>
          </div>
        ),
        render: (name: string, site: ApiSiteSummary) => {
          return isExternalSite(site, orgId) ? (
            <QuarantineExternalSiteHeader
              {...site}
              name={name}
              orgName={site.organisation.name}
              simpleTooltips={simpleTooltips}
              onRemove={
                onRemoveExternalSite
                  ? () => onRemoveExternalSite(site.id)
                  : undefined
              }
            />
          ) : (
            <QuarantineSiteHeader
              {...site}
              name={name}
              simpleTooltips={simpleTooltips}
            />
          );
        },
      },
      ...targetSites.map(
        target =>
          ({
            columnKey: `target:${target.id}`,
            dataIndex: 'id',
            title: (
              <QuarantineSiteHeader
                name={target.name}
                kind={target.kind}
                simpleTooltips={simpleTooltips}
              />
            ),
            render: (_sourceId: string, source: ApiSiteSummary) =>
              renderCell(source, target),
          }) as TableColumnProps<ApiSiteSummary>,
      ),
    ],
    [targetSites, renderCell, simpleTooltips, orgId, onRemoveExternalSite],
  );

  const scrollParentSelector = '.ant-table-body';
  const {
    ref: wrapperRef,
    vertical: headerShadowActive,
    horizontal: bodyShadowActive,
  } = useScrollFlags<HTMLDivElement>(scrollParentSelector);

  const screenSize = useScreenSize();

  // Only apply the smaller size classes if we've got more columns than we'd like for that screen size
  let sizeClass = 'lg';
  if (screenSize === 'sm' && columns.length > SM_COL_LIMIT) {
    sizeClass = 'sm';
  } else if (screenSize === 'md' && columns.length > MD_COL_LIMIT) {
    sizeClass = 'md';
  }

  const { height: windowHeight } = useWindowSize();

  return (
    <div ref={wrapperRef}>
      <div className="relative" ref={innerRef}>
        <Box gap="2">
          <Table
            className={classNames(
              'xo-quarantine-table',
              `xo-quarantine-table-${sizeClass}`,
              {
                'xo-table-body-shadow': bodyShadowActive,
                'xo-table-header-shadow': headerShadowActive,
              },
            )}
            striped={true}
            bordered={true}
            showHeader={true}
            columns={columns}
            dataSource={sourceSites}
            pagination={false}
            tableLayout="fixed"
            scroll={{
              // Stickiness
              y: windowHeight - 400,
              x: true,
            }}
            rowKey={s => s.id}
          />

          {canEditQuarantine && (
            <Box alignItems="flex-start">
              <AddExternalSiteButton
                existingSiteShortCodes={existingSiteShortCodes}
                onAddSite={onAddExternalSite}
              />
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

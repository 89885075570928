import { createContext, useContext, useEffect, useState } from 'react';
import { StoreApi, useStore } from 'zustand';

export const createStoreProvider = <
  TStoreState extends {},
  TStore extends StoreApi<TStoreState>,
>(
  providerName: string,
  createStoreFn: () => TStore,
) => {
  const Context = createContext<TStore>(null as any);

  const Provider = ({
    children,
    store: propsStore,
  }: {
    children: React.ReactNode;
    store?: TStore;
  }) => {
    const [store, setStore] = useState<TStore>(propsStore as TStore);
    useEffect(() => {
      // Ensure we only create the store once
      if (!store) {
        setStore(createStoreFn());
      }
    }, [store]);

    return store ? (
      <Context.Provider value={store}>{children}</Context.Provider>
    ) : null;
  };

  const useContextStore = <T extends any>(
    selector: (state: TStoreState) => T,
    equalityFn?: (a: T, b: T) => boolean,
  ) => {
    const store = useContext(Context);

    if (!store) {
      throw new Error(`${providerName} not found in component tree`);
    }

    return useStore(store, selector, equalityFn);
  };

  return { Provider, useStore: useContextStore };
};

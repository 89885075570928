import prettyBytes from 'pretty-bytes';
import { useHistory } from 'react-router-dom';
import { Button } from 'xo/components/button';
import Collapse from 'xo/components/collapse';
import { IconButton } from 'xo/components/icon-button';
import { SERVER_ERROR } from 'xo/constants';
import { Box, BoxProps, HStack, Text } from 'xo/core';
import { DatePeriod, formatIsoDate } from 'xo/date-utils';
import { ApiFileAttachmentKind } from 'xo/graphql/api/enums/file-attachment-kind.generated';
import { ApiScheduledMovementIngestionErrorKind } from 'xo/graphql/api/enums/scheduled-movement-ingestion-error-kind.generated';
import { ApiIngestScheduledMovementSpreadsheetErrorFragment } from 'xo/graphql/api/ingest-scheduled-movement-spreadsheet-error-fragment.generated';
import { useIngestScheduledMovementSpreadsheetMutation } from 'xo/graphql/api/ingest-scheduled-movement-spreadsheet-mutation.generated';
import { ApiScheduledMovementIngestionFragment } from 'xo/graphql/api/scheduled-movement-ingestion-fragment.generated';
import { arrivalDashboardRoutes } from 'xo/navigation/web-routes';
import { spacing } from 'xo/styles/restyle/spacing';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgCross } from 'xo/svg/svg-cross';
import { SvgExcel } from 'xo/svg/svg-excel';
import { SvgTick } from 'xo/svg/svg-tick';
import { SvgUpload } from 'xo/svg/svg-upload';
import { SvgWarning } from 'xo/svg/svg-warning';
import { createQueryParams } from '../../hooks/route-hooks';
import { TimeFilterQueryParam } from '../components/time-filter';
import {
  ArrivalDashboardContact,
  ArrivalDashboardUploadTemplateHint,
} from './arrival-dashboard-select-file-panel';
import { FileSelection } from './arrival-dashboard-upload-modal';

interface UploadMutationProps {
  selection: FileSelection;
  siteId: string;
  children: (props: {
    uploading: boolean;
    uploadErrorMessage?: string;
    uploadErrorKind?: ApiScheduledMovementIngestionErrorKind;
    onUpload: () => void;
    data?: ApiScheduledMovementIngestionFragment;
  }) => React.ReactNode;
}

const UploadMutation = ({
  selection,
  siteId,
  children,
}: UploadMutationProps) => {
  const [{ fetching, error: apiError, data }, ingestSpreadsheet] =
    useIngestScheduledMovementSpreadsheetMutation();
  const { file, error: selectionError } = selection;
  const onUpload = () => {
    if (file && siteId) {
      ingestSpreadsheet({
        input: {
          destinationSite: siteId,
          spreadsheet: {
            file,
            kind: ApiFileAttachmentKind.Spreadsheet,
          },
        },
      });
    }
  };

  const ingestError = data?.ingestScheduledMovementSpreadsheet as
    | ApiIngestScheduledMovementSpreadsheetErrorFragment
    | undefined;
  const uploadErrorMessage =
    selectionError ??
    (apiError ? SERVER_ERROR : undefined) ??
    ingestError?.message;
  const uploadErrorKind = ingestError?.kind;

  return (
    <>
      {children({
        onUpload,
        uploading: fetching,
        uploadErrorMessage,
        uploadErrorKind,
        data: data?.ingestScheduledMovementSpreadsheet as
          | ApiScheduledMovementIngestionFragment
          | undefined,
      })}
    </>
  );
};

export type OnViewSchedule = (
  range: Pick<ApiScheduledMovementIngestionFragment, 'startDate' | 'endDate'>,
) => void;

export interface ArrivalDashboardUploadFilePanelProps extends BoxProps {
  file: File;
  siteId: string;
  onRemove: () => void;
  onUpload: () => void;
  onClose: () => void;
  uploading: boolean;
  uploadErrorMessage?: string;
  uploadErrorKind?: ApiScheduledMovementIngestionErrorKind;
  data?: ApiScheduledMovementIngestionFragment;
}

export const ArrivalDashboardUploadFilePanel = ({
  file,
  siteId,
  onRemove,
  onUpload,
  onClose,
  uploading,
  uploadErrorMessage,
  uploadErrorKind,
  data,
  ...rest
}: ArrivalDashboardUploadFilePanelProps) => {
  const onViewSchedule = data
    ? () => {
        const reload =
          history.location.pathname === arrivalDashboardRoutes.root;
        history.push({
          pathname: arrivalDashboardRoutes.root,
          search: createQueryParams({
            [TimeFilterQueryParam.DateFrom]: formatIsoDate(data.startDate),
            [TimeFilterQueryParam.DateTo]: formatIsoDate(data.endDate),
            [TimeFilterQueryParam.Time]: DatePeriod.DateRange,
          }),
        });
        // react router won't refresh the rendering if the pathname matches when only the query params have changed, so force a refresh in that case
        if (reload) {
          window.location.reload();
        } else {
          onClose();
        }
      }
    : undefined;

  const history = useHistory();

  let rowIcon = <SvgUpload fill={colors.black} scale={1.2} />;
  let importMessage: JSX.Element | undefined = undefined;
  let importDescription: string | undefined = undefined;
  let rightLabel = 'UPLOAD';
  let onRightPress: (() => void) | undefined = onUpload;
  let leftLabel = 'CANCEL';
  let onLeftPress = onRemove;
  let uploadErrorHint = undefined;
  if (uploadErrorMessage) {
    rowIcon = <SvgWarning fill={colors.black} scale={1.2} />;
    importMessage = (
      <Text color="red.800" fontWeight="600">
        Import unsuccessful.
      </Text>
    );
    rightLabel = 'TRY AGAIN';
    importDescription = uploadErrorMessage;
    onRightPress = onRemove;

    if (
      uploadErrorKind ===
      ApiScheduledMovementIngestionErrorKind.ScheduleAlreadyExists
    ) {
      rightLabel = 'VIEW SCHEDULE';
      onRightPress = onViewSchedule;
      leftLabel = 'NEW UPLOAD';
      onLeftPress = onRemove;
    } else {
      uploadErrorHint = <ArrivalDashboardUploadTemplateHint />;
    }
  } else if (data) {
    rowIcon = (
      <Box bg="black" rounded="full" p="0.5">
        <SvgTick fill={colors.white} />
      </Box>
    );
    importMessage = (
      <Text color="blue.400" fontWeight="600">
        Import successful.
      </Text>
    );
    rightLabel = 'VIEW SCHEDULE';
    onRightPress = onViewSchedule;
    importDescription = 'View data from ingested schedule';
  } else if (!uploading) {
    rowIcon = (
      <IconButton variant="grey-dark" icon={<SvgCross />} onPress={onRemove} />
    );
  } else {
    importMessage = (
      <Text color="black" fontWeight="600">
        Importing...
      </Text>
    );
  }

  return (
    <Box {...rest}>
      <Box bg="grey.100" minHeight={spacing['48']} px="4">
        <Box direction="row" align="center" justify="space-between">
          <Box direction="row" align="center">
            <Box mr="4">
              <SvgExcel fill={colors.black} scale={3} />
            </Box>
            <Box>
              <Box flexWrap="wrap" maxWidth={220} my="1">
                <Text fontWeight="500">{file.name}</Text>
              </Box>
              <Box>
                <Text>{prettyBytes(file.size).toUpperCase()}</Text>
              </Box>
            </Box>
          </Box>

          {rowIcon}
        </Box>

        <Collapse active={uploading || !!importMessage}>
          <Box mt="2">
            <Box mt="1">{importMessage}</Box>
          </Box>
        </Collapse>

        <Box mt="1">
          <Text>{importDescription}</Text>
          {uploadErrorHint}
          {!!uploadErrorMessage ? <ArrivalDashboardContact /> : null}
        </Box>
      </Box>

      <HStack justify="space-between" w="full" mt="4">
        <Button
          variant="outline"
          onPress={onLeftPress}
          disabled={uploading}
          display={
            uploadErrorMessage &&
            uploadErrorKind !==
              ApiScheduledMovementIngestionErrorKind.ScheduleAlreadyExists
              ? 'none'
              : 'flex'
          }
        >
          {leftLabel}
        </Button>
        <Button
          variant="solid"
          onPress={onRightPress}
          loading={uploading}
          display={onRightPress ? 'flex' : 'none'}
        >
          {rightLabel}
        </Button>
      </HStack>
    </Box>
  );
};

export interface ArrivalDashboardUploadFileProps extends BoxProps {
  selection: FileSelection;
  siteId: string;
  onRemove: () => void;
  onClose: () => void;
}

export const ArrivalDashboardUploadFile = (
  props: ArrivalDashboardUploadFileProps,
) => (
  <UploadMutation {...props}>
    {renderProps => (
      <ArrivalDashboardUploadFilePanel
        {...props}
        {...renderProps}
        file={props.selection.file}
      />
    )}
  </UploadMutation>
);

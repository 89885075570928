import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { FormKind } from 'xo/rest-api';
import { useFormSubmit } from '../../hooks/shared-hooks';
import { usePostTermsAgreementUnauthed } from '../../hooks/user-network-hooks';
import { questionnaireKey } from '../../hooks/visitor-questionnaire-network-hooks';
import { UserModel } from '../../models/visitor-log-models';
import { ClickthroughAgreement } from '../components/clickthrough-agreement';
import { Container } from '../components/container';
import { Page } from '../components/page';
import { persistLocalStorageVisitor } from '../people-check-in/check-in-hooks';

export interface TermsPageUnauthedState {
  questionnaireId?: string;
  visitorId: string;
  kind?: FormKind;
  redirectTo: string;
  kioskMode: boolean;
}

export const TermsPageUnauthed: React.FC = () => {
  const { state } = useLocation<TermsPageUnauthedState>();
  const { questionnaireId, visitorId, kind, redirectTo, kioskMode } =
    state ?? {};

  const history = useHistory();
  useEffect(() => {
    // If we don't have the stuff we need, kick em out
    if (!visitorId || !redirectTo) {
      history.push('/');
    }
  });

  const [form] = useForm();

  // Take a T&Cs version timestamp on page load rather than submission for better accuracy
  const timestamp = useMemo(() => dayjs().toISOString(), []);
  const { mutate } = usePostTermsAgreementUnauthed(
    timestamp,
    questionnaireId && kind
      ? questionnaireKey(kind, questionnaireId)
      : undefined,
  );

  const { onSubmit, loading } = useFormSubmit({
    type: 'react-query',
    form,
    onSubmit: (_variables, opts) => mutate({ userId: visitorId }, opts),
    onSuccess: (user: UserModel) => {
      if (!kioskMode) {
        persistLocalStorageVisitor(user);
      }
      history.replace(redirectTo);
    },
  });

  return (
    <Page
      navbar={{
        variant: 'check-in',
      }}
      footer={{
        middle: {
          active: true,
          label: 'Continue',
          onClick: onSubmit,
          loading: loading,
        },
      }}
    >
      <Container>
        <ClickthroughAgreement form={form} />
      </Container>
    </Page>
  );
};

import React, { useState } from 'react';
import { SvgDelete } from 'xo/svg/svg-delete';
import { SvgRefresh } from 'xo/svg/svg-refresh';

import { Box, Text } from 'xo/core';
import { TestIDs } from 'xo/tests/test-ids';

import { Button, ButtonProps } from './button';
import { Modal } from './modal';
import { Tooltip } from './tooltip';

export interface DeleteButtonProps extends Omit<ButtonProps, 'onPress'> {
  onDelete: (updatedValue: boolean) => void;
  actionName?: string;
  allowsRestore?: boolean;
  deleted: boolean;
  deleting?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  entityName: string;
  deleteModalChildren?: React.ReactNode;
  restoreModalChildren?: React.ReactNode;
}

export const DeleteButton = ({
  onDelete,
  actionName = 'Delete',
  allowsRestore = true,
  deleted,
  deleting,
  disabled,
  disabledTooltip,
  entityName,
  deleteModalChildren,
  restoreModalChildren,
  testID,
  ...rest
}: DeleteButtonProps) => {
  const action = deleted ? 'Restore' : actionName;
  const buttonDisabled = disabled || (deleted && !allowsRestore);
  const icon = deleted ? <SvgRefresh /> : <SvgDelete />;
  const variant = deleted ? 'outline' : 'outline-warning';

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const button = (
    <Button
      variant={variant}
      disabled={buttonDisabled}
      loading={deleting}
      leftIcon={icon}
      onPress={() => setConfirmModalOpen(true)}
      testID={TestIDs.DeleteButton.delete(testID)}
      {...rest}
    >
      {action}
    </Button>
  );
  const wrappedButton =
    disabled && !!disabledTooltip ? (
      <Tooltip overlay={<Box>{disabledTooltip}</Box>}>{button}</Tooltip>
    ) : (
      button
    );

  return (
    <>
      <Modal
        open={confirmModalOpen}
        header={`${action} ${entityName.toLowerCase()}?`}
        okProps={{
          label: action.toUpperCase(),
          onPress: () => {
            onDelete(!deleted);
            setConfirmModalOpen(false);
          },
          testID: TestIDs.DeleteButton.confirm(testID),
        }}
        cancelProps={{
          label: 'Cancel',
          onPress: () => setConfirmModalOpen(false),
          testID: TestIDs.DeleteButton.cancel(testID),
        }}
      >
        {deleted
          ? restoreModalChildren || (
              <Text>
                This will restore the {entityName.toLowerCase()} with any
                information entered.
              </Text>
            )
          : deleteModalChildren || (
              <Text>
                This will remove the {entityName.toLowerCase()} from display and
                mark it as DELETED.
              </Text>
            )}
      </Modal>
      {wrappedButton}
    </>
  );
};

export enum AnalyticsEvent {
  TripStarted = 'Trip Started',
  TripStopped = 'Trip Stopped',
  LocationPermissionChanged = 'Location Permission Changed',
  ConsignmentPageStart = 'Consignment Page Start',
  ConsignmentPageSubmit = 'Consignment Page Submit',
  ConsignmentNoteDocumentsDownloaded = 'Consignment Note Documents Downloaded',
  SyncStart = 'Sync Start',
  SyncFailed = 'Sync Failed',
  PushNotificationTapped = 'Push Notification Tapped',
  VisitorVerify = 'Visitor Verify',
  MobileBannerStoreClicked = 'Mobile Banner Store Clicked',
  MobileBannerDismissed = 'Mobile Banner Dismissed',
}

import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import React from 'react';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgHelp } from 'xo/svg/svg-help';

export const IconTooltip: React.FC<{
  icon: JSX.Element;
  className?: string;
  placement?: TooltipPlacement;
  children?: React.ReactNode;
}> = ({ children, icon, className, placement }) => (
  <Tooltip
    overlay={<div className="text-center">{children}</div>}
    trigger={['click', 'hover']}
    placement={placement}
  >
    <a role="button" href="#help-tooltip" className={className}>
      {icon}
    </a>
  </Tooltip>
);

export const HelpTooltip: React.FC<{
  children?: React.ReactNode;
  className?: string;
  fill?: string;
}> = ({ children, className, fill = colors.blue[400] }) => (
  <IconTooltip className={className} icon={<SvgHelp fill={fill} scale={0.8} />}>
    {children}
  </IconTooltip>
);

import { useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Button } from 'xo/components/button';
import Collapse from 'xo/components/collapse';
import { Modal } from 'xo/components/modal';
import { Box, Text } from 'xo/core';
import { ControlledInput } from 'xo/forms/controlled-input';
import { FieldDisplay } from 'xo/forms/field-display';
import { Form, useForm } from 'xo/forms/form';
import { FormControl } from 'xo/forms/form-control';
import { Input } from 'xo/forms/input';
import { ApiModule } from 'xo/graphql/api/enums/module.generated';
import {
  ApiGetOpenSiteByShortCodeQuery,
  GetOpenSiteByShortCodeDocument,
} from 'xo/graphql/api/get-open-site-query.generated';
import { useClient } from 'xo/graphql/urql-provider';
import { SvgPlus } from 'xo/svg/svg-plus';
import { z } from 'zod';
import { env } from '../env';
import { ApiSiteSummary } from './settings-quarantine-utils';

export type ShortCodeValidatorFormModel = {
  shortCode: string;
};

export interface AddExternalSiteButtonProps {
  existingSiteShortCodes: string[];
  onAddSite: (site: ApiSiteSummary) => Promise<void>;
}

export const AddExternalSiteButton = ({
  existingSiteShortCodes,
  onAddSite,
}: AddExternalSiteButtonProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [okButtonLoading, setOkButtonLoading] = useState(false);

  const [site, setSite] = useState<ApiSiteSummary>();

  const [isOpen, setOpen] = useState(false);

  const client = useClient();

  const checkSiteAlreadyInMatrix = useCallback(
    (shortCode: string) => {
      return existingSiteShortCodes.includes(shortCode);
    },
    [existingSiteShortCodes],
  );

  const schema = z.object({
    shortCode: z
      .string()
      .refine(
        v => !checkSiteAlreadyInMatrix(v),
        'Site already exists in Quarantine Matrix.',
      ),
  });

  const methods = useForm<z.infer<typeof schema>>({
    schema,
  });

  const shortCode = useWatch({ name: 'shortCode', control: methods.control });

  const fieldError = methods.formState.errors.shortCode?.message;

  const canAddSite = site && !fieldError && !submitting;

  const onSearchSubmit = async (values: ShortCodeValidatorFormModel) => {
    setSubmitting(true);

    const result = await client
      .query<ApiGetOpenSiteByShortCodeQuery>(GetOpenSiteByShortCodeDocument, {
        input: { shortCode: values.shortCode.trim() },
      })
      .toPromise();

    setSubmitting(false);

    // there is no site with the given short code, show error
    const site = result?.data?.siteByShortCode;
    if (
      !site ||
      !site.modules.includes(ApiModule.Visitor) ||
      !site?.organisation.modules.includes(ApiModule.Visitor)
    ) {
      methods.setError('shortCode', {
        message: 'Invalid short code. Please try again.',
      });
      return;
    }

    // set the site if found, clear if not
    setSite(site);
  };

  const clearAll = () => {
    setSite(undefined);
    methods.reset();
  };

  const clearAllAndClose = () => {
    clearAll();
    setOpen(false);
  };

  const onOk = async () => {
    if (site) {
      setOkButtonLoading(true);
      await onAddSite(site);
      setOkButtonLoading(false);
      clearAllAndClose();
    }
  };

  const url = `${env.REACT_APP_STORYBOOK ? 'http://localhost:6006' : window.location.origin}/c/`;

  return (
    <>
      <Button
        onPress={() => {
          setOpen(true);
        }}
        variant="ghost"
        leftIcon={<SvgPlus />}
        testID="add-external-site"
      >
        Add external site
      </Button>

      <Modal
        open={isOpen}
        closeable
        header="Add External Site"
        buttonLayout="horizontal"
        cancelProps={{
          label: 'Cancel',
          onPress: clearAllAndClose,
          rounded: '2xl',
          variant: 'outline',
        }}
        okProps={{
          label: 'Add site',
          onPress: onOk,
          rounded: '2xl',
          variant: 'solid',
          loading: okButtonLoading,
          disabled: !canAddSite,
        }}
      >
        <Box gap="2">
          <Text>
            You can find the short code in the other organisation's ExoFlare
            Site Settings.
          </Text>
          <Text>
            Alternatively, look for the alphanumeric code in the check-in URL
            after <Text fontWeight="700">{url}</Text> on the QR sign.
          </Text>
        </Box>

        <Form<ShortCodeValidatorFormModel, ShortCodeValidatorFormModel>
          {...methods}
          controlStyleOverrides={{ paddingHorizontal: 0 }}
        >
          <Box width="full">
            <ControlledInput
              name="shortCode"
              label="Site short code"
              placeholder="Enter site short code"
              onChangeText={() => {
                if (site) {
                  clearAll();
                }
              }}
              clearable
              renderInput={props => (
                <Box direction="row" gap="2" width="full">
                  <Box flex={1}>
                    <Input {...props} />
                  </Box>
                  <Button
                    height="full"
                    onPress={methods.handleSubmit(onSearchSubmit)}
                    loading={submitting}
                    size="md"
                    disabled={
                      !!fieldError || submitting || !!site || !shortCode
                    }
                  >
                    Search
                  </Button>
                </Box>
              )}
            />

            <Collapse active={!!site}>
              <Box gap="2">
                <FormControl label="Site name" errorDisplay="none">
                  <FieldDisplay>{site?.name}</FieldDisplay>
                </FormControl>
                <FormControl label="Organisation" errorDisplay="none">
                  <FieldDisplay>{site?.organisation.name}</FieldDisplay>
                </FormControl>
              </Box>
            </Collapse>
          </Box>
        </Form>
      </Modal>
    </>
  );
};

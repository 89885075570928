import type { MenuProps } from 'antd';
import { Dropdown, Menu } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { Fragment, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgCaret } from 'xo/svg/svg-caret';
import { textMatchesInput } from 'xo/utils/search-utils';
import { onStopPropagation } from '../../hooks/shared-hooks';
import { Form, FormItem } from '../forms/form';
import { Input } from '../forms/input';

export interface NavBarDropdownOption {
  label: string;
  to: string;
  alwaysVisible?: boolean;
  divider?: boolean;
}

export interface NavBarDropdownProps {
  options: NavBarDropdownOption[];
  inverted?: boolean;
  children?: React.ReactNode;
}

export const NavBarDropdown = ({
  options,
  inverted,
  children,
}: NavBarDropdownProps) => {
  const [form] = useForm<{ search: string }>();
  const inputRef = useRef<HTMLInputElement>();

  const onVisibleChange = useCallback(
    (visible: boolean) => {
      if (!visible) {
        form.setFieldsValue({ search: '' });
      } else {
        setTimeout(() => {
          inputRef.current?.focus();
        });
      }
    },
    [form, inputRef],
  );

  return (
    <Form form={form} id="nav-dropdown">
      {form => {
        const filteredOptions = !form.getFieldValue('search')
          ? options
          : options.filter(
              o =>
                o.alwaysVisible ||
                textMatchesInput(form.getFieldValue('search'), o.label),
            );

        const MenuItems = [
          {
            key: 'menuItem',
            label: (
              <>
                <Menu.Item>
                  <FormItem name="search" noStyle>
                    <Input
                      inputRef={r => {
                        inputRef.current = r;
                      }}
                      placeholder="Enter search"
                      autoComplete="off"
                      onClick={onStopPropagation}
                    />
                  </FormItem>
                </Menu.Item>
                <Menu.Divider />
                {filteredOptions.map(o => (
                  <Fragment key={o.label}>
                    <Menu.Item>
                      <Link to={o.to}>{o.label}</Link>
                    </Menu.Item>
                    {o.divider && <Menu.Divider />}
                  </Fragment>
                ))}
                {filteredOptions.length === 0 && (
                  <Menu.Item>
                    <span className="italic">No matches</span>
                  </Menu.Item>
                )}
              </>
            ),
          },
        ];

        const items: MenuProps['items'] = [
          {
            key: 'menuDropdown',
            label: (
              <Menu
                style={{ maxHeight: '80vh', overflowY: 'auto' }}
                items={MenuItems}
              />
            ),
            style: { padding: 0, margin: 0 },
          },
        ];

        return (
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            onOpenChange={onVisibleChange}
          >
            <button className="flex items-center truncate">
              <span className="cursor-pointer">{children}</span>
              <span className="ml-4">
                <SvgCaret
                  direction="d"
                  fill={inverted ? colors.blue[600] : colors.white}
                />
              </span>
            </button>
          </Dropdown>
        );
      }}
    </Form>
  );
};

import dayjs, { Dayjs } from 'dayjs';
import { useContext } from 'react';
import { TIME_FORMAT } from 'xo/constants';
import { DateTimePickerWeb } from './date-time-picker-web';
import { TimePickerProps } from './date-time-picker.props';
import { FormControlContext } from './form-control';

const TimePickerWeb = ({
  value,
  onChange,
  onBlur,
  onFocus,
  clearable,
  ...rest
}: TimePickerProps) => {
  const control = useContext(FormControlContext);

  const onChangeCb = (date?: Dayjs) => {
    onChange && onChange(date?.format(TIME_FORMAT));
  };

  const parsedValue = value ? dayjs(value, TIME_FORMAT) : undefined;

  const onBlurCb = () => {
    onBlur && onBlur();
    // blur form control in timeout, otherwise value gets reset
    setTimeout(() => {
      control?.onBlur && control?.onBlur();
    }, 500);
  };

  const onFocusCb = () => {
    control?.onFocus && control?.onFocus();
    onFocus && onFocus();
  };

  return (
    <DateTimePickerWeb
      picker="time"
      onChange={onChangeCb}
      value={parsedValue}
      onBlur={onBlurCb}
      onFocus={onFocusCb}
      allowClear={clearable}
      {...rest}
    />
  );
};

export default TimePickerWeb;

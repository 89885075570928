import dayjs, { Dayjs } from 'dayjs';
import { clamp, range } from 'lodash';
import React, { useCallback } from 'react';
import {
  DatePeriod,
  datePeriodList,
  datePeriodToRange,
  financialYearAndWeekToDateRange,
} from 'xo/date-utils';
import { flexCenterBetween } from '../app-constants';
import { DateTimePicker } from '../forms/date-time-picker';
import { FormItem } from '../forms/form';
import { Input } from '../forms/input';
import { Select } from './select';
import { SingleSelectList } from './single-select-list';

const financialYearOptions = range(2021, dayjs().year() + 1, 1).map(year => ({
  value: year,
  label: `${year}-${year + 1}`,
}));

export interface DatePeriodValue {
  period: DatePeriod;
  from: Dayjs;
  to: Dayjs;
  // Week numbers where 1 = 1st wk of July
  financialWeek: number | undefined;
  // 2022 means July 2022 - June 2023
  financialYear: number | undefined;
}

export const normaliseDatePeriodValueEndpoints = (value: DatePeriodValue) => ({
  ...value,
  // this filters dates, so normalise to/from to include their full date
  from: value.from.startOf('day'),
  to: value.to.endOf('day'),
});

export interface DatePeriodSelectListProps {
  className?: string;
  onChange: (value: DatePeriodValue) => void;
  value: DatePeriodValue;
  onDatePickerVisibleChange?: (visible: boolean) => void;
  datePeriods?: DatePeriod[];
}

export const DatePeriodSelectList: React.FC<DatePeriodSelectListProps> = ({
  className,
  onChange,
  value,
  onDatePickerVisibleChange,
  datePeriods = datePeriodList,
}) => {
  const inputStyle = { maxWidth: '160px', width: '160px', minWidth: '160px' };

  const datePeriodOptions = datePeriods
    .filter(o => o !== DatePeriod.DateRange)
    .map(o => ({ label: o, value: o }));

  const onDatePickerClose = () =>
    onDatePickerVisibleChange && onDatePickerVisibleChange(false);
  const onDatePickerOpen = () =>
    onDatePickerVisibleChange && onDatePickerVisibleChange(true);

  const onChangeCb = useCallback(
    (value: DatePeriodValue) => {
      onChange(normaliseDatePeriodValueEndpoints(value));
    },
    [onChange],
  );

  const onFinancialWeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const week = clamp(e.target.valueAsNumber, 1, 53);
    if (value.financialYear && week) {
      const { from, to } = financialYearAndWeekToDateRange({
        week,
        year: value.financialYear,
      });
      onChangeCb({
        period: DatePeriod.DateRange,
        from,
        to,
        financialWeek: week,
        financialYear: value.financialYear,
      });
    } else {
      onChangeCb({ ...value, financialWeek: week });
    }
  };

  return (
    <div className={className}>
      <form className="ant-form ant-form-vertical">
        <div className={flexCenterBetween}>
          <FormItem label="From">
            <DateTimePicker
              id="date_period_select_from"
              style={inputStyle}
              value={value.from}
              onChange={newFrom => {
                newFrom &&
                  onChangeCb({
                    from: newFrom,
                    period: DatePeriod.DateRange,
                    to: newFrom.isAfter(value.to, 'day') ? newFrom : value.to,
                    financialWeek: undefined,
                    financialYear: value.financialYear,
                  });
              }}
              onOpen={onDatePickerOpen}
              onClose={() => setTimeout(onDatePickerClose)}
            />
          </FormItem>
          <div className="w-4" />
          <FormItem label="To">
            <DateTimePicker
              id="date_period_select_to"
              style={inputStyle}
              value={value.to}
              onChange={newTo => {
                newTo &&
                  onChangeCb({
                    from: newTo.isBefore(value.from, 'day')
                      ? newTo
                      : value.from,
                    period: DatePeriod.DateRange,
                    to: newTo,
                    financialWeek: undefined,
                    financialYear: value.financialYear,
                  });
              }}
              onOpen={onDatePickerOpen}
              onClose={onDatePickerClose}
            />
          </FormItem>
        </div>
        <div className={flexCenterBetween}>
          <FormItem label="Week number">
            <Input
              style={inputStyle}
              type="number"
              value={value.financialWeek ?? ''}
              placeholder="Enter 1-53"
              onChange={onFinancialWeekChange}
            />
          </FormItem>
          <div className="w-4" />
          <FormItem label="Financial year">
            <Select
              style={inputStyle}
              value={value.financialYear}
              onChange={year => {
                if (value.financialWeek && year) {
                  const { from, to } = financialYearAndWeekToDateRange({
                    week: value.financialWeek,
                    year,
                  });
                  onChangeCb({
                    period: DatePeriod.DateRange,
                    from,
                    to,
                    financialWeek: value.financialWeek,
                    financialYear: year,
                  });
                } else {
                  onChangeCb({ ...value, financialYear: year });
                }
              }}
              options={financialYearOptions}
              allowClear={true}
            />
          </FormItem>
        </div>
      </form>

      <SingleSelectList
        className="mt-4"
        options={datePeriodOptions}
        onChange={(period: DatePeriod) => {
          const [from, to] = datePeriodToRange(period);
          onChangeCb({
            period,
            from: period === DatePeriod.DateRange ? value.from : from,
            to: period === DatePeriod.DateRange ? value.to : to,
            financialWeek: undefined,
            financialYear: value.financialYear,
          });
        }}
        value={value.period}
      />
    </div>
  );
};

import { createStore } from 'zustand';
import { createStoreProvider } from './store-factory';

export interface InterfaceSettingsStoreState {
  xoUIHidden: boolean;
  setXoUIHidden: () => void;
}

const createInterfaceSettingsStore = () =>
  createStore<InterfaceSettingsStoreState>(set => ({
    xoUIHidden: false,
    setXoUIHidden: () => set({ xoUIHidden: true }),
  }));

type InterfaceSettingsStore = ReturnType<typeof createInterfaceSettingsStore>;

export const {
  Provider: InterfaceSettingsStoreProvider,
  useStore: useInterfaceSettingsStore,
} = createStoreProvider<InterfaceSettingsStoreState, InterfaceSettingsStore>(
  'InterfaceSettingsStoreProvider',
  createInterfaceSettingsStore,
);

import type { MenuProps as AntMenuProps } from 'antd';
import { Menu as AntMenu, Dropdown } from 'antd';
import { Box, Text } from 'xo/core';
import { colors } from 'xo/styles/tailwind-theme';
import { HelpTooltip } from './help-tooltip';
import { MenuProps } from './menu.props';

const Menu = ({ trigger, items }: MenuProps) => {
  if (items.length === 0) {
    return null;
  }

  const menuOptions = items.map(item => ({
    key: item.key,
    label: (
      <div onClick={() => (item.disabled ? {} : item.onPress())} role="button">
        <Box py="1" direction="row" align="center" justify="space-between">
          <Text
            color={
              item.disabled
                ? 'grey.400'
                : item.variant === 'warning'
                  ? 'red.500'
                  : 'brandGrey.600'
            }
          >
            {item.label}
          </Text>
          {item.tooltip && (
            <HelpTooltip iconColor={colors.blue[600]}>
              {item.tooltip}
            </HelpTooltip>
          )}
        </Box>
      </div>
    ),
    style: { paddingLeft: 35, paddingRight: 35 },
  }));

  const menuItems: AntMenuProps['items'] = [
    {
      key: 'transportSortMenu',
      label: <AntMenu style={{ padding: 0 }} items={menuOptions} />,
      style: { padding: 0, margin: 0 },
    },
  ];

  return (
    <Dropdown menu={{ items: menuItems }} trigger={['click']}>
      {trigger}
    </Dropdown>
  );
};

export default Menu;

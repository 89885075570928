import dayjs, { Dayjs } from 'dayjs';
import { last } from 'lodash';
import { formatDuration } from 'xo/transport/trip-summary-utils';
import { TIME_FORMAT, TODAY_LABEL } from './constants';
import { OptionType } from './hooks/component-hooks';
import logger from './logger';

interface DateFormatOptions {
  shortYear?: boolean;
  hideDay?: boolean;
  hideYear?: boolean;
  showDayOfWeek?: boolean;
}

interface DateTimeFormatOptions {
  dateOptions?: {
    shortYear?: boolean;
    showDayOfWeek?: boolean;
  };
  separator?: string;
}
interface DateFormatOptionsWithFallback extends DateFormatOptions {
  fallback?: string;
}

const dateTimeFormat = Intl.DateTimeFormat && Intl.DateTimeFormat();
const resolvedOptions =
  dateTimeFormat?.resolvedOptions && dateTimeFormat.resolvedOptions();
export const currentTimeZoneName = resolvedOptions?.timeZone;

//
// Date and time render functions
//

const getDateFormatString = (opts?: DateFormatOptions) => {
  const dayOfWeek = opts?.showDayOfWeek ? 'ddd ' : '';
  const day = opts?.hideDay ? '' : 'D ';
  // Month should always be displayed as MMM to prevent international ambiguity
  const month = 'MMM';
  const year = opts?.hideYear ? '' : opts?.shortYear ? ' YY' : ' YYYY';

  return `${dayOfWeek}${day}${month}${year}`;
};

export const renderDate = (date: Dayjs, opts?: DateFormatOptions) =>
  date.format(getDateFormatString(opts));

export const renderTime = (dateTime: Dayjs) => dateTime.format(TIME_FORMAT);

export const renderDateTime = (dateTime: Dayjs, opts?: DateTimeFormatOptions) =>
  `${renderDate(dateTime, opts?.dateOptions)}${opts?.separator ?? ' '}${renderTime(dateTime)}`;

export const renderTimeAndDate = (
  dateTime: Dayjs,
  opts?: DateTimeFormatOptions,
) =>
  `${renderTime(dateTime)}${opts?.separator ?? ' '}${renderDate(dateTime, opts?.dateOptions)}`;

export const renderCellDate = (date: Dayjs) => renderDate(date);

export const renderDateWithFallback = (
  date?: Dayjs,
  opts?: DateFormatOptionsWithFallback,
) => date?.format(getDateFormatString(opts)) ?? opts?.fallback ?? 'N/A';

export const renderTimeWithFallback = (
  date?: Dayjs,
  opts?: { fallback?: string },
) => date?.format(TIME_FORMAT) ?? opts?.fallback ?? 'N/A';

export const renderDateTimeWithFallback = (
  date?: Dayjs,
  opts?: DateTimeFormatOptions & { fallback?: string },
) => (date ? renderDateTime(date, opts) : opts?.fallback ?? 'N/A');

export const renderDateTimeWithUser = (
  date?: Dayjs,
  userDisplayName?: string,
) => {
  return (
    date ? [renderDate(date), 'at', date.format(TIME_FORMAT)] : ['Unknown time']
  )
    .concat(userDisplayName ? ['by', userDisplayName] : [])
    .join(' ');
};
export const renderTimeOrDateTime = (
  date: Dayjs | undefined,
  comparisonDate: Dayjs,
) =>
  date?.isSame(comparisonDate, 'day')
    ? renderTimeWithFallback(date)
    : renderDateTimeWithFallback(date);
export const renderStartToEndTime = (start: Dayjs, end: Dayjs) =>
  `${renderTimeWithFallback(start)} - ${renderTimeWithFallback(end)}`;
export const renderStartToEndDate = (
  start: Dayjs,
  end: Dayjs,
  opts?: { shortYear?: boolean },
) =>
  start.isSame(end, 'day')
    ? renderDateWithFallback(start, opts)
    : `${renderDateWithFallback(start, opts)} - ${renderDateWithFallback(end, opts)}`;

// The last seen column only has data from the 1.22 release so lastSeen: null for a user older isn't a guarantee of never-seeing
const LAST_SEEN_RELIABLE_FROM = dayjs(`2022-02-01T00:00+11:00`);
export const renderUserLastSeen = (lastSeen?: Dayjs, createdAt?: Dayjs) =>
  lastSeen
    ? renderDateTimeWithFallback(lastSeen)
    : createdAt?.isAfter(LAST_SEEN_RELIABLE_FROM)
      ? 'Never'
      : 'UNKNOWN';

export enum DatePeriod {
  ThisWeek = 'This week',
  NextWeek = 'Next week',
  LastWeek = 'Last week',
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Yesterday = 'Yesterday',
  DateRange = 'Date range',
}

export const datePeriodToRange = (period: DatePeriod): [Dayjs, Dayjs] => {
  const from = dayjs().startOf('day');
  const to = dayjs().endOf('day');

  const config: Record<DatePeriod, () => [Dayjs, Dayjs]> = {
    [DatePeriod.ThisWeek]: () => [from.day(0), to.day(6)],
    [DatePeriod.NextWeek]: () => [from.day(7), to.day(13)],
    [DatePeriod.LastWeek]: () => [from.day(-7), to.day(-1)],
    [DatePeriod.Today]: () => [from, to],
    [DatePeriod.Tomorrow]: () => [from.add(1, 'days'), to.add(1, 'days')],
    [DatePeriod.Yesterday]: () => [
      from.subtract(1, 'days'),
      to.subtract(1, 'days'),
    ],
    // Configured by user
    [DatePeriod.DateRange]: () => [from, to],
  };

  return config[period]();
};

export const datePeriodList = [
  DatePeriod.ThisWeek,
  DatePeriod.NextWeek,
  DatePeriod.LastWeek,
  DatePeriod.Today,
  DatePeriod.Tomorrow,
  DatePeriod.Yesterday,
  DatePeriod.DateRange,
];

export const defaultPeriod = DatePeriod.Today;
export const defaultPeriodRange = datePeriodToRange(defaultPeriod);

export const formatDateRange = (start: Dayjs, end: Dayjs) => {
  const same = start.isSame(end, 'date');
  return `${same ? 'on' : 'between'} ${renderDate(start)}${
    same ? '' : ` and ${renderDate(end)}`
  }`;
};

export const formatElapsedTime = (dateTime: Dayjs) => {
  const now = dayjs();
  const duration = formatDuration({
    start: dateTime,
    end: now,
    compact: true,
  });
  return duration.totalMinutes < 0
    ? renderTimeAndDate(dateTime, { separator: ', ' })
    : duration.totalMinutes === 0
      ? 'now'
      : duration.totalMinutes <= 720
        ? duration.desc + ' ago'
        : dateTime.isSame(now, 'day')
          ? renderTime(dateTime)
          : renderDate(dateTime);
};

export const createTimeOption = (dateTime: Dayjs) => ({
  key: dateTime.toISOString(),
  label: renderTime(dateTime),
  value: dateTime.toISOString(),
});

export const getTimeOptionsFromRange = ({
  minHour,
  maxHour,
}: {
  minHour: number;
  maxHour: number;
}): OptionType<string>[] => {
  if (minHour < 0 || minHour > 23 || maxHour < 0 || maxHour > 23) {
    logger.error(
      `Invalid hour range provided: minHour: ${minHour}, maxHour: ${maxHour}`,
    );
    return [];
  }

  const hourCount = maxHour - minHour;

  const options = Array.from({ length: hourCount + 1 }, (_, i) => {
    const dateTime = dayjs()
      .hour(minHour + i)
      .startOf('hour');

    return createTimeOption(dateTime);
  });

  return options;
};

export const formatDateOrTime = (value: Dayjs, type: 'date' | 'time') => {
  if (type === 'time') {
    return renderTime(value);
  }

  return value.isSame(dayjs(), 'day') ? TODAY_LABEL : renderDate(value);
};

//
// Date and time formatting functions for passing data around
//

export const isAfterOrSame = (date: Dayjs, other: Dayjs) =>
  date.isSame(other, 'minute') || date.isAfter(other, 'minute');

export const formatIsoDate = (date: Dayjs) => date.format('YYYY-MM-DD');
export const formatLocalDateTime = (date: Dayjs) =>
  date.format('YYYY-MM-DDTHH:mm:ss');
export const formatMaybeLocalDateTime = (date?: Dayjs) =>
  date ? formatLocalDateTime(date) : undefined;

export const parseTime = (timeString: string) => dayjs(timeString, 'HH:mm');
export const parseMaybeTime = (timeString: string | undefined) =>
  timeString ? parseTime(timeString) : undefined;
export const parseFormatTime = (timeString: string) =>
  parseTime(timeString).format(TIME_FORMAT);

export const parseDate = (dateString: string) =>
  dayjs(dateString, 'YYYY-MM-DD');

export const segmentDateRanges = (dates: Dayjs[]): Dayjs[][] =>
  dates.reduce((acc, n) => {
    if (last(last(acc))?.add(1, 'day').isSame(n, 'day')) {
      acc.splice(acc.length - 1, 1, last(acc)!.concat(n));
      return acc;
    }

    return acc.concat([[n]]);
  }, [] as Dayjs[][]);

export const minutesFromMidnight = (date: Dayjs) => {
  const localDate = date.local();
  return localDate.diff(localDate.startOf('day'), 'minute');
};

// Some FYs don't follow the first-Sunday rule
const financialYearStartSpecialCases = new Map([[2024, dayjs('2024-06-30')]]);

export const financialYearAndWeekToDateRange = ({
  week,
  year,
}: {
  // 1 - 53
  week: number;
  // 2022 for 2022/2023 etc
  year: number;
}) => {
  const firstDayOfFy =
    financialYearStartSpecialCases.get(year) ??
    dayjs(new Date(year, 6, 1))
      .startOf('day')
      // First Sunday after the 1st July
      .weekday(7);
  const from = firstDayOfFy.add(week - 1, 'week');

  return {
    from,
    to: from.add(1, 'week').subtract(1, 'day').endOf('day'),
  };
};

// Get the year of the first Sunday of the previous July
export const dateToFinancialYear = (date: Dayjs) => {
  const year = date.year();
  // when does this FY start?
  const { from } = financialYearAndWeekToDateRange({ week: 1, year });

  return date.isBefore(from) ? year - 1 : year;
};

export const unixToDate = (unix: number) => dayjs(unix);

export const combineDateAndTime = (date: Dayjs, time: Dayjs, local?: boolean) =>
  dayjs(
    `${date.format('YYYY-MM-DD')}T${time.format(`HH:mm:ss${local ? '' : 'Z'}`)}`,
  );

export const getTodayYesterdayAppender = (date: Dayjs) => {
  const today = dayjs();
  const extraText = date.isSame(today, 'day')
    ? ' (Today)'
    : date.isSame(today.subtract(1, 'day'), 'day')
      ? ' (Yesterday)'
      : '';

  return extraText;
};

// Tries to work out the current timezone, but falls back if it can't be determined (can happen on some browsers). Consumers can define their own more sensible fallback if desired
export const getCurrentTimezone = (
  defaultTz: string = 'Australia/Sydney',
): string => Intl.DateTimeFormat().resolvedOptions().timeZone ?? defaultTz;

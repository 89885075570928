import type { MenuProps } from 'antd';
import { Dropdown, Tooltip } from 'antd';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import { useOnClickOutside } from 'react-hanger';
import {
  DatePeriod,
  datePeriodToRange,
  dateToFinancialYear,
  financialYearAndWeekToDateRange,
  renderStartToEndDate,
} from 'xo/date-utils';
import { colors } from 'xo/styles/tailwind-theme';
import { SvgCaret } from 'xo/svg/svg-caret';
import { SvgCross } from 'xo/svg/svg-cross';
import {
  useDateQueryParam,
  useIntQueryParam,
  useQueryParamState,
  useQueryParams,
} from '../../hooks/route-hooks';
import {
  flexCenterBetween,
  flexCenterCenter,
  flexCenterEnd,
  flexColStartEnd,
} from '../app-constants';
import {
  DatePeriodSelectList,
  DatePeriodValue,
  normaliseDatePeriodValueEndpoints,
} from '../components/date-period-select-list';

export const getDatePeriodFromQueryParam = ({
  financialWeek,
  financialYear,
  date,
  time,
  defaultPeriod,
}: {
  time: DatePeriod;
  financialYear?: number;
  financialWeek?: number;
  date?: Dayjs;
  defaultPeriod: DatePeriod;
}): DatePeriodValue => {
  if (date) {
    return normaliseDatePeriodValueEndpoints({
      period: DatePeriod.DateRange,
      from: date,
      to: date,
      financialWeek: undefined,
      financialYear: dateToFinancialYear(date),
    });
  } else if (financialWeek && financialYear) {
    return normaliseDatePeriodValueEndpoints({
      period: DatePeriod.DateRange,
      ...financialYearAndWeekToDateRange({
        week: financialWeek,
        year: financialYear,
      }),
      financialWeek,
      financialYear,
    });
  }

  const period = time ?? defaultPeriod;
  const range = datePeriodToRange(time ?? defaultPeriod);
  return normaliseDatePeriodValueEndpoints({
    period,
    from: range[0],
    to: range[1],
    financialWeek: undefined,
    financialYear: dateToFinancialYear(range[0]),
  });
};

export interface TimeFilterProps {
  className?: string;
  value: DatePeriodValue;
  onChange: (period: DatePeriodValue) => void;
  title?: string;
  titleTooltip?: string;
  defaultPeriod?: DatePeriod;
  selectableDatePeriods?: DatePeriod[];
}

export const TimeFilter = ({
  className,
  value,
  onChange,
  title = 'Time',
  titleTooltip,
  defaultPeriod = DatePeriod.ThisWeek,
  selectableDatePeriods,
}: TimeFilterProps) => {
  const [visible, onVisibleChange] = useState(false);
  const [datePickerOpen, onSetDatePickerOpen] = useState(false);
  const ref = useOnClickOutside(
    () => !datePickerOpen && visible && onVisibleChange(false),
  );

  const titleText = (
    <span className="text-xs font-semibold uppercase text-black">{title}</span>
  );

  const items: MenuProps['items'] = [
    {
      key: String(visible),
      label: (
        <div
          className="bg-white px-2 shadow-bold md:mx--10"
          ref={ref as React.RefObject<HTMLDivElement>}
        >
          <div className={flexCenterEnd}>
            <button
              id="transport_time_filters_close"
              className={classNames(
                'rounded-full p-1 hover:bg-grey-200',
                flexCenterCenter,
              )}
              onClick={() => onVisibleChange(false)}
            >
              <SvgCross fill={colors.black} />
            </button>
          </div>
          <DatePeriodSelectList
            value={value}
            onChange={onChange}
            onDatePickerVisibleChange={onSetDatePickerOpen}
            datePeriods={selectableDatePeriods}
          />
        </div>
      ),
      style: { padding: 0, margin: 0 },
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      open={visible}
      placement="bottom"
    >
      <button
        className={classNames(
          className,
          'w-full p-2 transition-all duration-200 ease-in hover:bg-grey-200',
          {
            'rounded-md bg-grey-100': !visible,
            'rounded-t-md bg-white': visible,
          },
        )}
        onClick={() => onVisibleChange(true)}
      >
        <span className={flexCenterBetween}>
          <span className={flexColStartEnd}>
            {titleTooltip ? (
              <Tooltip overlay={titleTooltip} placement="top">
                {titleText}
              </Tooltip>
            ) : (
              titleText
            )}
            <span
              className={classNames(
                flexCenterCenter,
                'whitespace-nowrap text-base font-semibold uppercase text-blue-600',
              )}
            >
              <span className="mr-1">
                {value.financialWeek && value.financialYear
                  ? `Week ${value.financialWeek}`
                  : value.period}
              </span>
              <span>({renderStartToEndDate(value.from, value.to)})</span>
            </span>
          </span>
          <span className="w-full" />
          <SvgCaret
            direction={visible ? 'u' : 'd'}
            fill={colors.blue[600]}
            scale={1.2}
          />
        </span>
      </button>
    </Dropdown>
  );
};

export enum TimeFilterQueryParam {
  Time = 'time',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  FinancialYear = 'fy',
  FinancialWeek = 'week',
}

export const useTimeFilterQueryParams = (
  defaultPeriod: DatePeriod = DatePeriod.ThisWeek,
): [DatePeriodValue, (value: DatePeriodValue) => void] => {
  const queryParams = useQueryParams();

  const [financialYear, onSetFinancialYear] = useIntQueryParam(
    TimeFilterQueryParam.FinancialYear,
    dateToFinancialYear(dayjs()),
  );
  const [financialWeek, onSetFinancialWeek] = useIntQueryParam(
    TimeFilterQueryParam.FinancialWeek,
  );

  const defaultDate = useMemo(
    () => (queryParams.date ? dayjs(queryParams.date as string) : undefined),
    [queryParams],
  );
  const defaultTime = useMemo(
    () => (queryParams.time ? (queryParams.time as DatePeriod) : defaultPeriod),
    [queryParams, defaultPeriod],
  );
  const defaultValue = getDatePeriodFromQueryParam({
    financialWeek,
    financialYear,
    date: defaultDate,
    time: defaultTime,
    defaultPeriod,
  });

  const [time, onSetTime] = useQueryParamState<DatePeriod>(
    TimeFilterQueryParam.Time,
    defaultValue.period,
  );
  const [dateFrom, onSetDateFrom] = useDateQueryParam(
    TimeFilterQueryParam.DateFrom,
    defaultValue.from,
    'YYYY-MM-DD',
  );
  const [dateTo, onSetDateTo] = useDateQueryParam(
    TimeFilterQueryParam.DateTo,
    defaultValue.to,
    'YYYY-MM-DD',
  );

  const onChange = (rawValue: DatePeriodValue) => {
    const value = normaliseDatePeriodValueEndpoints(rawValue);
    onSetDateFrom(value.from);
    onSetDateTo(value.to);
    onSetTime(value.period);
    onSetFinancialWeek(value.financialWeek);
    onSetFinancialYear(value.financialYear);
  };

  const value: DatePeriodValue = normaliseDatePeriodValueEndpoints({
    period: time ?? defaultPeriod,
    from: dateFrom!,
    to: dateTo!,
    financialWeek,
    financialYear,
  });

  return [value, onChange];
};

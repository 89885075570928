import { ApiAddressBookTag } from 'xo/graphql/api/enums/address-book-tag.generated';
import { ApiInternalCommodityKind } from 'xo/graphql/api/enums/internal-commodity-kind.generated';
import { ApiSiteArchetype } from 'xo/graphql/api/enums/site-archetype.generated';
import { ApiVehicleKind } from 'xo/graphql/api/enums/vehicle-kind.generated';
import { createEnumOptions } from 'xo/utils/option-utils';

export const flexCenterStart = 'flex items-center justify-start';
export const flexCenterCenter = 'flex items-center justify-center';
export const flexCenterBetween = 'flex items-center justify-between';
export const flexCenterEnd = 'flex items-center justify-end';
export const flexStartStart = 'flex items-start justify-start';
export const flexStartBetween = 'flex items-start justify-between';

export const flexColEndEnd = 'flex flex-col items-end justify-end';
export const flexColEndStart = 'flex flex-col items-end justify-start';
export const flexColStartEnd = 'flex flex-col items-start justify-end';
export const flexColCenterCenter = 'flex flex-col items-center justify-center';
export const flexColCenterBetween =
  'flex flex-col items-center justify-between';

export const transitionAll = 'transition-all ease-in-out duration-200';

export const VISIT_REASON_OTHER_OPTION = 'Other';
export const VISIT_REASON_OTHER_PREFIX = `${VISIT_REASON_OTHER_OPTION} - `;
export const VISIT_REASON_OPTIONS = [
  'Farming work',
  'Maintenance / repairs',
  'Animal health / nutrition',
  'Audit / compliance',
  'Meeting',
  'Training',
  'R&D',
  VISIT_REASON_OTHER_OPTION,
];

export const {
  options: siteArchetypeOptions,
  getLabel: getSiteArchetypeLabel,
} = createEnumOptions(ApiSiteArchetype, {
  [ApiSiteArchetype.AiCentre]: {
    label: 'AI centre',
  },
});

export const {
  options: addressBookTagOptions,
  getLabel: getAddressBookTagLabel,
} = createEnumOptions(ApiAddressBookTag);

export const { options: vehicleKindOptions, getLabel: getVehicleKindLabel } =
  createEnumOptions(ApiVehicleKind);

export const {
  options: internalCommodityKindOptions,
  getLabel: getInternalCommodityKindLabel,
} = createEnumOptions(ApiInternalCommodityKind);
